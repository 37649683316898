import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Hidden",
        "componentName": "Hidden"
      }}>{`<Hidden above="xs" as="span">
  Hidden above xs.
</Hidden>
<Hidden above="sm" below="sm" as="span">
  Hidden above sm & below sm.
</Hidden>
<Hidden above="md" below="md" as="span">
  Hidden above md & below md.
</Hidden>
<Hidden above="lg" below="lg" as="span">
  Hidden above lg & below lg.
</Hidden>
<Hidden below="xl" as="span">
  Hidden below xl.
</Hidden>
<Hidden print>
  <Paragraph>Hidden on print.</Paragraph>
</Hidden>
<Hidden screen>
  <Paragraph>
    Hidden on screen. But use VisuallyHidden if you want it to be read by screenreaders.
  </Paragraph>
</Hidden>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Hidden" mdxType="Props" />
    <h2 {...{
      "id": "note-use-screenreader-when-possible",
      "style": {
        "position": "relative"
      }
    }}>{`NOTE: Use screenreader when possible`}</h2>
    <p>{`If you want to hide an element on the screen, but to be read by screenreaders,
use the `}<inlineCode parentName="p">{`Screenreader`}</inlineCode>{` component instead.`}</p>
    <h2 {...{
      "id": "above",
      "style": {
        "position": "relative"
      }
    }}>{`Above`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph>
  The following text is hidden above xs:
  <Hidden above="xs" as="span">Hidden above xs.</Hidden>
</Paragraph>
<Paragraph>
  The following text is hidden above sm:
  <Hidden above="sm" as="span">Hidden above sm.</Hidden>
</Paragraph>
<Paragraph>
  The following text is hidden above md:
  <Hidden above="md" as="span">Hidden above md.</Hidden>
</Paragraph>
<Paragraph>
  The following text is hidden above lg:
  <Hidden above="lg" as="span">Hidden above lg.</Hidden>
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "below",
      "style": {
        "position": "relative"
      }
    }}>{`Below`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph>
  The following text is hidden below sm:
  <Hidden below="sm" as="span">Hidden below sm.</Hidden>
</Paragraph>
<Paragraph>
  The following text is hidden below md:
  <Hidden below="md" as="span">Hidden below md.</Hidden>
</Paragraph>
<Paragraph>
  The following text is hidden below lg:
  <Hidden below="lg" as="span">Hidden below lg.</Hidden>
</Paragraph>
<Paragraph>
  The following text is hidden below xl:
  <Hidden below="xl" as="span">Hidden below xl.</Hidden>
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "print",
      "style": {
        "position": "relative"
      }
    }}>{`Print`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph>
  The following text is hidden on print:
  <Hidden print>
    <Paragraph>Try to print me.</Paragraph>
  </Hidden>
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "screen",
      "style": {
        "position": "relative"
      }
    }}>{`Screen`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph>
  The following text is hidden on screen:
  <Hidden screen>
    <Paragraph>Nope, not visible on screen.</Paragraph>
  </Hidden>
</Paragraph>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      